import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import {
  ServiceCard,
  MainBanner,
  TestimonialCard,
  SlidingHero,
  ContactUsBanner,
  SubHeroColumns,
} from '../../components/shared';

import { Box, Text, Heading, Container, SimpleGrid, Flex, Image, VStack, HStack, Icon } from '@chakra-ui/react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useHomeResidentialPage } from '../../GraphQL/Queries';

SwiperCore.use([Pagination, Navigation]);

const ResidentialHomePage = ({ location }) => {
  const data = useHomeResidentialPage();

  const { acf_hero, acf_text_block_title, acf_contact_cta, acf_image_text_block, acf_testimonials } = data.pageBy;

  const settings = {
    pagination: {
      el: '.testimonial-residential__swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.testimonial-residential__swiper-button-next',
      prevEl: '.testimonial-residential__swiper-button-prev',
    },
  };

  return (
    <Layout location={location}>
      <SEO title="Home" />

      <SlidingHero
        images={acf_hero.sliderImages}
        heroHeadline={
          <>
            <div contentEditable="true" dangerouslySetInnerHTML={{ __html: acf_hero.sliderTitle }} />
          </>
        }
      />

      <SubHeroColumns
        heading={acf_text_block_title.title}
        body={acf_text_block_title.content}
        secondHeading={acf_text_block_title.secondTitle}
        secondBody={acf_text_block_title.secondContent}
      />

      <Container>
        <Box py="2rem" paddingRight={{ base: '2rem', md: 'unset' }}>
          <SimpleGrid minChildWidth="250px" spacing="40px">
            {data.residentialServices.nodes.map((service, i) => (
              <ServiceCard
                key={i}
                url={service.acf_service_info.serviceUrl}
                title={service.title}
                image={service.acf_service_info.background.sourceUrl}
                icon={service.acf_service_info.icon.sourceUrl}
                description={service.excerpt.replace(/<\/?[^>]+(>|$)/g, '')}
                imagePosition="30% 50%"
              />
            ))}
          </SimpleGrid>
        </Box>
      </Container>

      <MainBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
        ctaUrl={acf_image_text_block.blockList[0].imageTextBlockButton.url}
      />

      <Container maxW="1600px" mt={{ lg: '10rem' }}>
        <Box position="relative" height={{ lg: '573px' }} my="3rem">
          <Box mr={{ base: '2rem', lg: '0' }} pt="11rem">
            <VStack
              bg="primary"
              mt={{ base: '-2rem', lg: 'unset' }}
              p={{ base: '2rem', lg: '2rem 3rem' }}
              color="white"
              maxW={{ lg: '463px' }}
              position={{ lg: 'absolute' }}
              right={{ lg: '13rem' }}
              bottom={{ lg: '0' }}
            >
              <Heading textStyle="h2">{acf_testimonials.mainTitle}</Heading>
              <Text textStyle="pFooter">{acf_testimonials.mainContent}</Text>
            </VStack>
            <Box maxW={{ md: '800px' }} w={{ md: '45vw' }} pos="relative">
              <TestimonialCard>
                <Swiper pagination={settings.pagination} navigation={settings.navigation} autoplay>
                  {acf_testimonials.testimonials.map((testimonial, index) => (
                    <SwiperSlide>
                      <Box p={{ base: '2rem 2rem', lg: '5rem 8rem' }}>
                        <HStack align="flex-start" spacing="1rem">
                          <Image
                            src="/icons/quote.svg"
                            boxSize="40px"
                            mt="-1rem"
                            display={{ base: 'none', lg: 'block' }}
                          />
                          <VStack align="flex-start" spacing="1rem">
                            <Text textStyle="p">{testimonial.testimonialText}</Text>
                            <Text textStyle="pBold" color="primary">
                              {testimonial.testimonialAuthor}
                            </Text>
                          </VStack>
                        </HStack>
                      </Box>
                    </SwiperSlide>
                  ))}
                  <Box
                    mt={{ base: '-2rem', lg: '-3rem' }}
                    px={{ base: '2.3rem', lg: '12rem' }}
                    pb="2rem"
                    className="testimonial-residential__swiper-pagination"
                  />

                  <Flex
                    w="50px"
                    h="65px"
                    pos="absolute"
                    bottom="0"
                    right="0"
                    m="0 1rem 1rem 0"
                    direction="column"
                    align="center"
                    justify="space-between"
                    zIndex="10"
                    display={{ base: 'none', lg: 'flex' }}
                  >
                    <button className="testimonial-residential__swiper-button-prev">
                      <Icon
                        as={AiOutlineArrowLeft}
                        bg="secondary"
                        w={{ base: '45px', md: '25px' }}
                        h={{ base: '45px', md: '25px' }}
                        p={{ base: '3', md: '1' }}
                        mr="2"
                        borderRadius="50%"
                        color="white"
                      />
                    </button>
                    <button className="testimonial-residential__swiper-button-next">
                      <Icon
                        as={AiOutlineArrowRight}
                        bg="secondary"
                        w={{ base: '45px', md: '25px' }}
                        h={{ base: '45px', md: '25px' }}
                        p={{ base: '3', md: '1' }}
                        mr="2"
                        borderRadius="50%"
                        color="white"
                      />
                    </button>
                  </Flex>
                </Swiper>
              </TestimonialCard>
            </Box>
          </Box>
          <Image
            src="/images/meeting.png"
            maxWidth={{ lg: '922px' }}
            height={{ base: '181px', lg: '573px' }}
            pl={{ base: '2rem', lg: '0' }}
            width="100%"
            objectFit="cover"
            objectPosition="center"
            position="absolute"
            top="0"
            right={{ lg: '0' }}
            zIndex="-1"
          />
        </Box>
      </Container>
      {/* Testimonials --END-- */}

      <ContactUsBanner
        heading={acf_contact_cta.contactCtaTitle}
        description={acf_contact_cta.contactCtaContent}
        image={acf_contact_cta.contactCtaBackgroundImage.sourceUrl}
        altText={acf_contact_cta.contactCtaBackgroundImage.altText}
        url={acf_contact_cta.contactCtaButton.url}
      />
    </Layout>
  );
};

export default ResidentialHomePage;
